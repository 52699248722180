.home-container.black-and-white {
    filter: grayscale(100%) brightness(100%);
    pointer-events: none;
    /* Disable pointer events during black and white mode */
}

.top-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Black with 70% opacity */
    z-index: 999;
    /* Ensure the top layer is on top of other elements */
}