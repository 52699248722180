.pcards-container {
  margin-top: 1rem;
  padding: 10px;

}

.pcard {
  background-color: #fff;
  box-shadow: 10px 10px 40px 2px rgba(128, 128, 128, 0.4);
  margin: 0 auto;
  border-radius: 10px;
  height: 350px;
}

.pcard .pcard-media {
  background-position: center;
  background-size: cover;
  object-fit: fit;
  border-radius: 10px 10px 0 0;
  position: relative;
}

.pcard .pcard-media .discount {
  background-color: #e9924f;
  font-size: 12px;
  position: absolute;
  border-radius: 0 4px 4px 0;
  max-width: 110px;
  left: 0;
  color: #fff;
}

.pcard .pcard-media .discount p {
  font-weight: 400;
}

.pcard .pcard-media .pro-discount {
  font-size: 12px;
  background-color: #e83940;
  position: absolute;
  border-radius: 0 4px 4px 0;
  color: #fff;
}

.pcard .pcard-media .delivery-time {
  position: absolute;
  background-color: rgba(26, 171, 26, 0.821);
  max-width: 100px;
  padding: 3px 6px;
  font-size: 12px;
  right: 3%;
  bottom: 6%;
  border-radius: 4px;
}

.pcard .pcard-description {
  padding: 5px;
}

.pcard .pcard-description .about-place {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.pcard .pcard-description .about-place .place-name {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
}

.pcard .pcard-description .about-place .place-speciality,
.pcard .pcard-description .about-place .per-person {
  font-size: 13px;
  color: rgba(128, 128, 128, 0.97);

}

.pcard .pcard-description .about-place .rating {
  font-size: 14px;
  border-radius: 4px;
  background-color: #0a6d0acc;
  color: #fff;
  font-weight: 700;
  text-align: center;
  padding: 2px 0;
  margin-bottom: 6px;
  margin-left: 30px;
}

.pricing {
  margin-bottom: 10px;
  padding-left: 2rem;
}

.unit {
  padding-left: 1rem;
  text-align: end;
}


.quantity-control {
  display: flex;
  justify-content: right;
}

.quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0ea04e;
  border-radius: 5px;
  width: 32px;
  color: white
}

.ribbon-wrapper {
  margin-left: -14px;
}

.ribbon-pop {
  background: linear-gradient(270deg, #e59813 5.25%, #3faa07 96.68%);
  display: inline-block;
  padding: 10px 40px 10px 20px;
  color: white;
  position: relative;
}

.ribbon-pop:after {
  content: "";
  width: 0;
  height: 0;
  top: 0;
  position: absolute;
  right: 0;
  border-right: 20px solid #fff;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.ribbon-pop:before {
  height: 0;
  width: 0;
  border-top: 15px solid #381f57;
  border-left: 15px solid transparent;
  bottom: -15px;
  position: absolute;
  content: "";
  left: 0;
}