


.sec-title-style1 {
position: relative;
display: block;
padding-bottom: 50px;
}
.sec-title-style1.max-width{
position: relative;
display: block;
margin: auto 0;
padding-bottom: 52px;    
}
.sec-title-style1.pabottom50 {
padding-bottom: 42px;
}
.sec-title-style1 .title {
position: relative;
display: block;
color: #131313;
font-size: 36px;
line-height: 46px;
font-weight: 700;
text-transform: uppercase;
}
.sec-title-style1 .title.clr-white{
color: #ffffff;
}
.sec-title-style1 .decor {
position: relative;
display: block;
width: 70px;
height: 5px;
margin: 19px 0 0;
}
.sec-title-style1 .decor:before{
position: absolute;
top: 0;
right: 0;
bottom: 0;
width: 5px;
height: 5px;
background: #FFA500;
border-radius: 50%;
content: "";
}
.sec-title-style1 .decor:after{
position: absolute;
top: 0;
right: 10px;
bottom: 0;
width: 5px;
height: 5px;
background: #FFA500;
border-radius: 50%;
content: "";
}
.sec-title-style1 .decor span {
position: absolute;
top: 0;
left: 0;
bottom: 0;
width: 50px;
height: 1px;
background: #FFA500;
margin: 2px 0;
}


.sec-title-style1 .text{
position: relative;
display: block;
margin: 7px 0 0;
}
.sec-title-style1 .text p{
position: relative;
display: inline-block;
padding: 0 15px;
color: #131313;
font-size: 14px;
line-height: 16px;
font-weight: 700;
text-transform: uppercase;
margin: 0;
}
.sec-title-style1 .text.clr-yellow p{
color: #FFA500;
}
.sec-title-style1 .text .decor-left{
position: relative;
top: -2px;
display: inline-block;
width: 70px;
height: 5px;
background: transparent;
}
.sec-title-style1 .text .decor-left span {
position: absolute;
top: 0;
left: 0;
bottom: 0;
width: 50px;
height: 1px;
background: #FFA500;
content: "";
margin: 2px 0;
}
.sec-title-style1 .text .decor-left:before{
position: absolute;
top: 0;
right: 0;
bottom: 0;
width: 5px;
height: 5px;
background: #FFA500;
border-radius: 50%;
content: "";
}
.sec-title-style1 .text .decor-left:after{
position: absolute;
top: 0;
right: 10px;
bottom: 0;
width: 5px;
height: 5px;
background: #FFA500;
border-radius: 50%;
content: "";
}

.sec-title-style1 .text .decor-right{
position: relative;
top: -2px;
display: inline-block;
width: 70px;
height: 5px;
background: transparent;
}
.sec-title-style1 .text .decor-right span {
position: absolute;
top: 0;
right: 0;
bottom: 0;
width: 50px;
height: 1px;
background: #FFA500;
content: "";
margin: 2px 0;
}
.sec-title-style1 .text .decor-right:before{
position: absolute;
top: 0;
left: 0;
bottom: 0;
width: 5px;
height: 5px;
background: #FFA500;
border-radius: 50%;
content: "";
}
.sec-title-style1 .text .decor-right:after{
position: absolute;
top: 0;
left: 10px;
bottom: 0;
width: 5px;
height: 5px;
background: #FFA500;
border-radius: 50%;
content: "";
}

.sec-title-style1 .bottom-text{
position: relative;
display: block;
padding-top: 16px;
}
.sec-title-style1 .bottom-text p{
color: #848484;
font-size: 16px;
line-height: 26px;
font-weight: 400;
margin: 0;
}
.sec-title-style1 .bottom-text.clr-gray p{
color: #cdcdcd;    
}
.contact-address-area{
position: relative;
display: block;
background: #ffffff;
}
.contact-address-area .sec-title-style1.max-width {
padding-bottom: 72px;
}

.single-contact-address-box {
    margin-top: 2rem;
position: relative;
display: block;
background: #131313;
padding: 85px 30px 77px;
width: 400PX;
}
.single-contact-address-box .icon-holder{
position: relative;
display: block;
padding-bottom: 24px;
}
.single-contact-address-box .icon-holder span:before{
font-size: 75px;
}
.single-contact-address-box h3{
color: #ffffff;
margin: 0px 0 9px;
}
.single-contact-address-box h2{
color: #FFA500;
font-size: 24px;
font-weight: 600;
margin: 0 0 19px;
}
.single-contact-address-box a{
color: #ffffff;
}

.single-contact-address-box.main-branch {
background: #FFA500;
padding: 53px 30px 51px;
margin-top: -20px;
margin-bottom: -20px;
color: #ffffff;
}
.single-contact-address-box.main-branch h3{
font-size: 18px;
font-weight: 700;
margin: 0 0 38px;
text-transform: uppercase;
text-align: center;
}
.single-contact-address-box.main-branch .inner{
position: relative;
display: block;

}
.single-contact-address-box.main-branch .inner ul{
position: relative;
display: block;
overflow: hidden;
}
.single-contact-address-box.main-branch .inner ul li{
position: relative;
display: block;
padding-left: 110px;
border-bottom: 1px solid #737373;
padding-bottom: 23px;
margin-bottom: 24px;
}
.single-contact-address-box.main-branch .inner ul li:last-child{
border: none;
margin-bottom: 0;
padding-bottom: 0;
}
.single-contact-address-box.main-branch .inner ul li .title{
position: absolute;
top: 2px;
left: 0;
display: inline-block;
}
.single-contact-address-box.main-branch .inner ul li .title h4{
font-size: 18px;
font-weight: 600;
line-height: 24px;
text-transform: capitalize;
border-bottom: 2px solid #a5821e;
}

.single-contact-address-box.main-branch .inner ul li .text{
position: relative;
display: block;
}
.single-contact-address-box.main-branch .inner ul li .text p{
font-size: 16px;
line-height: 24px;
font-weight: 600;
margin: 0;
}







