header {
  display: grid;
  padding: 0.5rem 0 0 0;
  height: 80vh;
}
.header-img {
  width: 100%;
  align-self: center;
  margin-top: 10rem;
  padding-top: 2rem;
  height: 350px;
  width: 350px;
}
.header-content {
  display: flex;
  flex-direction: column;
}

.content-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}
.content-main h1 {
  font-size: 3rem;
  margin-bottom: 1.5rem;
}
.content-main button {
  background: #000;
  color: #fff;
  width: 210px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  outline: none;
  border: none;
  font-size: 1rem;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  cursor: pointer;
  margin-top: 2.5rem;
}

.content-main p {
  font-weight: 500;
}

@media (max-width: 768px) {
  .content-main h1 {
    margin-bottom: 1rem;
  }
  .content-main p {
    font-size: 0.8rem;
  }
  .content-main button {
    font-size: 0.8rem;
    width: 150px;
    padding: 0.5rem 1rem;
    margin-top: 1.5rem;
  }
  .bannerImage {
    margin-top:9rem ;
  }
}
